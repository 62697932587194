<template>
  <div class="container">
    <div class="pageDrawer pageOpen">
      <div id="pageHeader">
        <div class="container max-800">
          <div id="infoHolder">
            <i class="tio-help-outlined"> </i>
            Privacy policy
          </div>
        </div>
        <div id="closeHolder">
          <img
            src="@/assets/img/close.svg"
            id="closePage"
            @click="closePage()"
          />
        </div>
        <div class="clearfix"></div>
      </div>
      <div class="container text-start mt-5 pt-3 max-800">
        <article>
          <h1>Responsible Gaming</h1>
          <ul>
            <li>
              Gambling may be addictive and harmful if not controlled and kept
              in moderation. Please play responsibly. If you are concerned about
              your gambling activity, you can contact the following
              organizations for help: GamCare (http://www.gamcare.org.uk/) and
              GambleAware (https://www.begambleaware.org).
            </li>
            <li>
              We offer responsible gaming measures for those Customers who wish
              to restrict their gambling activities on the Website. The
              following responsible gaming mechanisms are available and can be
              accessed by contacting either our Customer Support or Curacao
              Gaming Control Board (GCB):<br />(a). self-exclusion;<br />(b). a
              cool down period;<br />(c). financial limits on bets and
              losses;<br />(d). limits on session time.
            </li>
            <li>
              If you wish to be self-excluded from our Website, you should
              request it by contacting our Customer Support or Curacao Gaming
              Control Board (GCB) . Self-exclusion will prevent you from using
              your Player Account for an indefinite period. We will close your
              Player Account and refund the remaining balance. We will not
              distribute to you any communications about available promotions on
              our Website. You will not be able to reopen your Player Account.
            </li>
            <li>
              If you need a break from gambling on the Website, you can ask us
              for a cool down period. Once the cool down period is requested,
              you will receive a confirmation from us within 48 hours. In all
              cases, your request will be complied with as soon as it is
              acknowledged by us. A cool down period is not the same as
              self-exclusion.
            </li>
            <li>
              You can choose to impose a limit on the maximum bets and losses
              that you may incur on our Website. You may also choose to impose a
              time limit per session.
            </li>
            <li>
              In the event you request an adjustment to your limits (including
              limits on deposits, bets and losses) or a cool down period<br />(a).
              new limits that are stricter than those previously imposed, or an
              extension of a cool down period, will take effect immediately from
              the time of an adjustment; (b). new limits that are more lenient
              than those previously imposed will only take effect after a
              seven-day cooling off period from the time of an adjustment;<br />(c).
              the cool down period shall only be shortened after a seven-day
              cooling off period, unless the existing cool down period is due to
              end within that seven-day timeframe, in which case it shall remain
              unaltered.
            </li>
            <li>
              Underage play is prohibited on our Website. We implement various
              measures to ensure that children cannot use our Website, including
              date of birth entry upon registration. We also assist our
              Customers in preventing their children from accessing gambling
              websites. One of the ways you can stop children from accessing our
              Website is with a filtering program such as Net Nanny, CYBERsitter
              or the Internet Content Rating Association. They will help you
              block certain websites and schedule your children’s access to the
              Internet. You can also keep a record of the websites that your
              children visit during this scheduled and monitored time.
            </li>
            <li>
              All capitalized terms used herein shall have the same meaning as
              set forth in the Terms &amp; Conditions of the Website, unless
              otherwise stated.
            </li>
          </ul>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    document.body.style.overflow = "hidden";
    return {
      history: window.history,
    };
  },
  methods: {
    closePage() {
      this.history.back();
      document.body.style.removeProperty("overflow");
    },
  },
};
</script>
<style scoped>
.aboutList {
  margin: 0px;
  padding: 0px;
  text-align: left;
  list-style-type: none;
  padding-top: 10px;
}
.aboutList li {
  padding: 15px;
  line-height: 25px;
}
</style>
